import { Theme } from "@mui/material";
import { Components } from "@react-admin/ra-enterprise";

export const RaConfirm: Components["RaConfirm"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        alignIcon: "right",
        "& .MuiButton-root": {},
        "& .RaConfirm-confirmPrimary": {
          boxShadow: "none",
          borderRadius: "32px",
          minWidth: "min-content",

          padding: "14px 30px",

          fontFamily: "General sans",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "14px",
          letterSpacing: "0",
          border: `1px solid`,
          borderColor: "grey.200",

          color: `white`,
          backgroundColor: "red.700",

          ":focus, :hover, :active": {
            backgroundColor: "red.900",
          },
          ":disabled": {
            backgroundColor: "grey.50",
            color: "grey.50",
            borderColor: "transparent",
          },
          "& .MuiButton-startIcon": {
            display: "inherit",

            color: `white`,
            "& .MuiSvgIcon-root": {
              width: "16px",

              height: "16px",
            },
          },
        },
      }),
  },
};
